<template>
  <div>
    <div class="tabs-content">
      <a-row>
        <a-col :span="24">
          <h3 class="title">
            <a-row>
              <a-col :span="12"> AÉREO</a-col>
              <a-col class="a-right" :span="12">
                <a-button
                  size="small"
                  @click="addFlightSectionRow(flightSectionRows.length)"
                  class="add-button color"
                >
                  Adicionar aéreo
                </a-button>
              </a-col>
            </a-row>
          </h3>
        </a-col>

        <a-col :span="24">
          <a-collapse
            class="flights-collapse"
            accordion
            expand-icon-position="right"
            v-for="(flightSection, index) in flightSectionRows"
            :default-active-key="activeFlightKey"
            :key="index"
            :bordered="false"
            @change="fillFlightFields(flightSection, index)"
          >
            <a-form-item class="flight-type ml-20">
              <a-radio-group
                :options="[
                  {
                    label: 'Pagamento Regular',
                    value: 'Regular',
                    disabled: milesOrderExists(flightSection.id),
                  },
                  {
                    label:
                      tempContract.status == 'new'
                        ? 'Pagamento com Milhas (salve o contrato para liberar)'
                        : 'Pagamento com Milhas',
                    value: 'Milhas',
                    disabled: pagamentoMilhas,
                  },
                ]"
                @change="fillFlightFields(flightSection, index)"
                v-decorator="[
                  `flight_${flightSection.id}_type`,
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                  ,
                ]"
              />
            </a-form-item>

            <a-collapse-panel>
              <template slot="header">
                <i
                  class="fas fa-plane-departure mr-10"
                  style="font-size: 14px"
                ></i>
                AÉREO
                {{ flightSection.id }}
              </template>
              <a-icon
                v-if="
                  flightSectionRows.length == flightSection.id &&
                  flightSection.id != 1 &&
                  !milesOrderExists(flightSection.id)
                "
                slot="extra"
                type="delete"
                style="color: red"
                @click="removeFlightSectionRow(flightSection.id)"
              />
              <!-- Regular -->
              <a-row class="details">
                <a-col
                  v-if="
                    tempContract[`flight_${flightSection.id}_type`] == 'Milhas'
                  "
                  :span="24"
                >
                  <h3 class="title">
                    <a-row>
                      <a-col :span="12"> CIAS AÉREAS </a-col>
                      <a-col class="a-right" :span="12">
                        <a-button
                          v-if="flightSection.airlines.length < 10"
                          size="small"
                          @click="
                            updateFlightAirline(
                              flightSection.airlines.length,
                              flightSection.id
                            )
                          "
                          class="add-button color"
                        >
                          Adicionar cia
                        </a-button>
                      </a-col>
                    </a-row>
                  </h3>
                </a-col>
                <a-col
                  v-if="
                    tempContract[`flight_${flightSection.id}_type`] == 'Milhas'
                  "
                  class="mb-20"
                  :span="24"
                >
                  <a-row
                    :gutter="20"
                    v-for="(airline, index) in flightSection.airlines"
                    :key="index"
                  >
                    <a-col :span="4">
                      <a-form-item class="travel-input">
                        <label :class="'filled'">Cia aérea </label>
                        <a-select
                          style="width: 100%"
                          placeholder="Selecione a cia"
                          show-search
                          :allow-clear="true"
                          :disabled="
                            checkIfMilesOrderExists(
                              flightSection.id,
                              airline.id
                            )
                          "
                          @change="
                            clearMilesProgram(flightSection.id, airline.id)
                          "
                          v-decorator="[
                            `flight_${flightSection.id}_airline_${airline.id}`,
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                        >
                          <a-select-option
                            v-for="(airline, index) in airlinesList"
                            :key="index"
                            :value="
                              airline.name +
                              '|' +
                              airline.id +
                              '|' +
                              airline.trading_name +
                              '|' +
                              airline.company_name +
                              '|' +
                              airline.cnpj
                            "
                          >
                            <img
                              v-if="airline.logo"
                              :src="airline.logo"
                              width="13"
                            />
                            {{ airline.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>

                    <a-col
                      :span="4"
                      v-if="
                        tempContract[`flight_${flightSection.id}_type`] ==
                        'Milhas'
                      "
                    >
                      <a-form-item class="travel-input">
                        <label :class="'filled'">Pgm. de milhas </label>
                        <a-select
                          style="width: 100%"
                          placeholder="Pgm. de milhas"
                          show-search
                          :allow-clear="true"
                          :disabled="
                            checkIfMilesOrderExists(
                              flightSection.id,
                              airline.id
                            )
                          "
                          v-decorator="[
                            `flight_${flightSection.id}_airline_${airline.id}_miles_program`,
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                        >
                          <a-select-option
                            v-for="(program, index) in filterMilesPrograms(
                              tempContract[
                                `flight_${flightSection.id}_airline_${airline.id}`
                              ]
                            )"
                            :key="index"
                            :value="program.id"
                          >
                            {{ program.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>

                    <a-col
                      :span="14"
                      v-if="
                        tempContract[`flight_${flightSection.id}_type`] ==
                        'Milhas'
                      "
                    >
                      <a-form-item
                        class="travel-input travel-select-expandable"
                        style="height: auto !important"
                      >
                        <a-select
                          class="travellers-selector"
                          mode="multiple"
                          placeholder="Selecione os passageiros"
                          style="width: 100%; height: auto !important"
                          :disabled="
                            checkIfMilesOrderExists(
                              flightSection.id,
                              airline.id
                            )
                          "
                          v-decorator="[
                            `flight_${flightSection.id}_airline_${airline.id}_travellers`,
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                        >
                          <a-select-option
                            v-for="(traveller, index) of travellers"
                            :key="index"
                            :value="traveller.id"
                          >
                            {{ traveller.name }}
                            {{ traveller.lastName }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>

                    <a-col :span="2">
                      <a-tooltip
                        placement="topRight"
                        title="Adicionar nova cia"
                        v-if="airline.id == 1"
                      >
                      </a-tooltip>

                      <div
                        v-if="
                          !checkIfMilesOrderExists(flightSection.id, airline.id)
                        "
                      >
                        <a-button
                          v-if="
                            airline.id === flightSection.airlines.length &&
                            flightSection.airlines.length > 1
                          "
                          size="small"
                          type="danger"
                          shape="circle"
                          icon="delete"
                          @click="
                            removeFlightAirline(airline.id, flightSection.id)
                          "
                        >
                        </a-button>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>

                <a-col :span="24">
                  <h3 class="title">
                    <a-row>
                      <a-col :span="12"> VIAJANTES </a-col>
                      <a-col class="a-right" :span="12">
                        <a-button
                          v-if="flightSection.travellers.length < 10"
                          size="small"
                          @click="
                            updateFlightTraveller(
                              flightSection.travellers.length,
                              flightSection.id
                            )
                          "
                          class="add-button color"
                        >
                          Adicionar viajante
                        </a-button>
                      </a-col>
                    </a-row>
                  </h3>

                  <a-row
                    v-for="(traveller, index) in flightSection.travellers"
                    :key="index"
                    :gutter="20"
                    class="travellers-rows"
                  >
                    <a-col :span="1">
                      <font class="user">
                        <a-icon type="user" />
                        <font class="number">
                          {{ traveller.id }}
                        </font>
                      </font>
                    </a-col>

                    <a-col :span="5">
                      <a-form-item class="travel-input">
                        <a-select
                          placeholder="Selecione um viajante"
                          v-decorator="[
                            `flight_${flightSection.id}_traveller_${traveller.id}`,
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                          :disabled="milesOrderExists(flightSection.id)"
                          @change="
                            flightTravellerAge(flightSection.id, traveller.id)
                          "
                        >
                          <a-select-option
                            v-for="(traveller, index) of travellers"
                            :key="index"
                            :value="traveller.id"
                          >
                            {{ traveller.name }}
                            {{ traveller.lastName }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>

                    <a-col
                      :span="4"
                      v-if="
                        tempContract[`flight_${flightSection.id}_type`] ==
                        'Milhas'
                      "
                    >
                      <a-form-item>
                        <span class="travel-input">
                          <label :class="'filled'">Milhas </label>
                          <a-input
                            class="travel-input"
                            placeholder="Milhas"
                            v-currency="{
                              currency: {
                                prefix: '',
                                suffix: '',
                              },
                              distractionFree: true,
                              valueAsInteger: false,
                              precision: 0,
                              autoDecimalMode: true,
                              allowNegative: false,
                            }"
                            v-decorator="[
                              `flight_${flightSection.id}_traveller_${traveller.id}_miles`,
                            ]"
                            :disabled="milesOrderExists(flightSection.id)"
                          >
                          </a-input>
                        </span>
                      </a-form-item>
                    </a-col>

                    <a-col
                      v-if="
                        tempContract[`flight_${flightSection.id}_type`] ==
                        'Milhas'
                      "
                      :span="4"
                    >
                      <a-form-item>
                        <span class="travel-input">
                          <label :class="'filled'">Valor (R$) </label>
                          <a-input
                            class="travel-input"
                            placeholder="Valor (R$) "
                            v-currency
                            @blur="calcFlightTotalValue(flightSection.id)"
                            v-decorator="[
                              `flight_${flightSection.id}_traveller_${traveller.id}_value`,
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'Obrigatório',
                                  },
                                ],
                              },
                            ]"
                          >
                          </a-input>
                        </span>
                      </a-form-item>
                    </a-col>

                    <a-col
                      v-if="
                        tempContract[`flight_${flightSection.id}_type`] ==
                        'Milhas'
                      "
                      :span="2"
                    >
                      <a-form-item class="travel-input">
                        <label :class="'filled'">Moeda Taxas </label>
                        <a-select
                          placeholder="Moeda Taxas"
                          v-decorator="[
                            `flight_${flightSection.id}_traveller_${traveller.id}_taxes_currency`,
                            {
                              rules: [
                                {
                                  required:
                                    tempContract[
                                      `flight_${flightSection.id}_type`
                                    ] == 'Milhas'
                                      ? true
                                      : false,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                        >
                          <a-select-option value="BRL"> R$ </a-select-option>

                          <a-select-option value="USD"> $ </a-select-option>

                          <a-select-option value="EUR"> € </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>

                    <a-col
                      v-if="
                        tempContract[`flight_${flightSection.id}_type`] ==
                        'Milhas'
                      "
                      :span="3"
                    >
                      <a-form-item>
                        <span class="travel-input">
                          <label :class="'filled'"
                            >Taxas ({{
                              flightTaxesCurrency(
                                flightSection.id,
                                traveller.id
                              )
                            }})
                          </label>
                          <a-input
                            class="travel-input"
                            placeholder="Taxas"
                            v-currency="{
                              currency: flightTaxesCurrency(
                                flightSection.id,
                                traveller.id
                              ),
                            }"
                            @blur="calcFlightTotalValue(flightSection.id)"
                            v-decorator="[
                              `flight_${flightSection.id}_traveller_${traveller.id}_taxes`,
                              {
                                rules: [
                                  {
                                    required:
                                      tempContract[
                                        `flight_${flightSection.id}_type`
                                      ] == 'Milhas'
                                        ? true
                                        : false,
                                    message: 'Obrigatório',
                                  },
                                ],
                              },
                            ]"
                          >
                          </a-input>
                        </span>
                      </a-form-item>
                    </a-col>

                    <a-col :span="4">
                      <a-form-item>
                        <span class="travel-input">
                          <label :class="'filled'">Localizador </label>
                          <a-input
                            class="travel-input"
                            placeholder="Localizador"
                            v-decorator="[
                              `flight_${flightSection.id}_traveller_${traveller.id}_locator`,
                              {
                                rules: [
                                  {
                                    required:
                                      tempContract[
                                        `flight_${flightSection.id}_type`
                                      ] == 'Milhas'
                                        ? true
                                        : false,
                                    message: 'Obrigatório',
                                  },
                                ],
                              },
                            ]"
                          >
                          </a-input>
                        </span>
                      </a-form-item>
                    </a-col>

                    <a-col :span="1">
                      <div v-if="!milesOrderExists()">
                        <a-button
                          v-if="
                            traveller.id == flightSection.travellers.length &&
                            traveller.id != 1
                          "
                          size="small"
                          type="danger"
                          shape="circle"
                          icon="delete"
                          style="position: relative; top: 10px; left: -8px"
                          @click="
                            removeFlightTraveller(
                              traveller.id,
                              flightSection.id
                            )
                          "
                        >
                        </a-button>
                      </div>

                      <a-tooltip
                        placement="topRight"
                        title="Adicionar todos viajantes"
                      >
                        <a-button
                          v-if="traveller.id == 1"
                          size="small"
                          type="primary"
                          shape="circle"
                          style="position: relative; top: 10px; left: -8px"
                          @click="addAllTravellers(flightSection.id, 'flight')"
                        >
                          <a-icon
                            type="usergroup-add"
                            style="font-size: 17px"
                          />
                        </a-button>
                      </a-tooltip>
                    </a-col>
                  </a-row>
                </a-col>

                <a-col :span="24">
                  <a-row class="flight-rows mt-30">
                    <a-row>
                      <a-col :span="24">
                        <FlightCollapse
                          :flightSectionID="flightSection.id"
                          v-for="(flight, index) in flightSection.sections"
                          :totalRows="flightSection.sections.length"
                          :flightRows="flightSection.sections"
                          :add="index === 0 ? true : false"
                          :key="index"
                          :flightSection="flightSection"
                          :class="`flight-row flight-${flight.id}`"
                          :id="flight.id"
                          :contract="tempContract"
                          :tempContract="tempContract"
                          :form="form"
                          :airlinesList="airlinesList"
                          :flight_travellers="contract.meta.flight_travellers"
                          @updateFlightRows="updateFlightRows"
                          @removeFlightRows="removeFlightRows"
                        />
                      </a-col>
                    </a-row>
                  </a-row>
                </a-col>
              </a-row>
            </a-collapse-panel>
          </a-collapse>
        </a-col>

        <a-col
          v-if="tempContract.status != 'new' || tempContract.status != 'new2'"
          :span="24"
        >
          <a-divider />
          <a-button
            v-if="tempContract.request_miles_status == true"
            class="relative mr-20 btn-miles"
            size="large"
            type="primary"
            style="top: -1px; padding-left: 20px"
            ghost
          >
            <a-popconfirm
              ok-text="Sim"
              cancel-text="Não"
              @confirm="confirmTicketed"
              @cancel="cancelTicketed"
            >
              <template slot="title">
                Tem certeza que deseja marcar o ticket
                <br />
                aéreo como bilhetado?
              </template>
              <a-checkbox
                v-model="ticketed"
                style="font-size: 13px; font-weight: 500; position: relative"
              >
                Bilhetado
              </a-checkbox>
            </a-popconfirm>
          </a-button>
        </a-col>
      </a-row>
    </div>

    <a-form-item style="display: none">
      <span>
        <a-input v-decorator="[`flight_sections_rows`, {}]"> </a-input>
      </span>
    </a-form-item>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";
import FlightCollapse from "../collapses/FlightCollapse.vue";

export default {
  name: "FlightSectionsCollapse",
  components: { FlightCollapse },
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    travellers: Array,
  },
  data() {
    return {
      flightSectionRows: [],
      activeFlightKey: [],
      airlinesList: [],
      pagamentoMilhas: true,
      ticketed: false,
    };
  },
  beforeMount() {
    this.getAirlines();

    this.flightSectionRows = this.tempContract.flight_sections_rows
      ? JSON.parse(this.tempContract.flight_sections_rows)
      : [
          {
            id: 1,
            travellers: [{ id: 1 }],
            airlines: [{ id: 1 }],
            sections: [{ id: 1 }],
          },
        ];
  },
  mounted() {
    this.pagamentoMilhas = this.tempContract.status != "new" ? false : true;

    this.ticketed = this.tempContract.flight_is_ticketed == "1" ? true : false;

    this.flightSectionRows.forEach((flight, index) => {
      this.fillFlightFields(flight, index);

      flight.travellers((traveller) => {
        this.flightTravellerAge(flight.id, traveller.id);
      });
    });
  },
  methods: {
    confirmTicketed() {
      this.ticketed = true;
      setTimeout(() => {
        this.tempContract.flight_is_ticketed = true;

        this.$http.post("/log/create", {
          user_id: this.$store.state.userData.id,
          module_id: this.tempContract.id,
          module: "contract",
          action: "flight-ticketed-contract",
          description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} marcou o ticket aéreo como Bilhetado no contrato ID ${this.tempContract.id}.`,
        });
      }, 20);
    },
    cancelTicketed() {
      this.ticketed = false;
      setTimeout(() => {
        this.tempContract.flight_is_ticketed = false;
      }, 20);
    },
    flightTaxesCurrency(flightId, travellerId) {
      let theCurrency =
        this.tempContract[
          `flight_${flightId}_traveller_${travellerId}_taxes_currency`
        ];

      if (!theCurrency && theCurrency == undefined) {
        theCurrency = "BRL";
      }
      return theCurrency;
    },
    getAirlines() {
      this.$http
        .get(`/airline/list?page=1&per_page=200&order=ascend&order-by=name`)
        .then(({ data }) => {
          data;
          this.airlinesList = data.data;

          data.data.forEach((cia) => {
            if (cia.miles_programs.length > 0) {
              cia.miles_programs.forEach((program) => {
                this.milesProgramList.push(program);
              });
            }
          });
        })
        .catch(({ response }) => {
          response;
        });
    },
    emitValueUpdate() {
      setTimeout(() => {
        this.$refs.verifyTotal.verifyTotal();
      }, 10);
    },
    filterMilesPrograms(airline) {
      let filtered = [];

      if (airline != undefined) {
        this.airlinesList.forEach((cia) => {
          if (cia.miles_programs.length > 0) {
            cia.miles_programs.forEach((program) => {
              if (program.airline_id == airline.split("|")[1]) {
                filtered.push(program);
              }
            });
          }
        });
      }

      return filtered;
    },
    updateFlightRows(id, flightSectionID) {
      this.flightSectionRows[flightSectionID - 1].sections.push({
        id: id + 1,
      });

      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
    removeFlightRows(id, flightSectionID) {
      for (var i in this.flightSectionRows[flightSectionID - 1].sections) {
        if (this.flightSectionRows[flightSectionID - 1].sections[i].id == id) {
          this.flightSectionRows[flightSectionID - 1].sections.splice(i, 1);
          break;
        }
      }

      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
    fillFlightFields(flightSection, index) {
      this.activeFlightKey = index;

      setTimeout(() => {
        flightSection.travellers.forEach((traveller) => {
          this.form.setFieldsValue({
            [`flight_${flightSection.id}_traveller_${traveller.id}`]:
              this.tempContract[
                `flight_${flightSection.id}_traveller_${traveller.id}`
              ] != undefined
                ? parseInt(
                    this.tempContract[
                      `flight_${flightSection.id}_traveller_${traveller.id}`
                    ]
                  )
                : undefined,
            [`flight_${flightSection.id}_traveller_${traveller.id}_miles`]:
              this.tempContract[
                `flight_${flightSection.id}_traveller_${traveller.id}_miles`
              ],
            [`flight_${flightSection.id}_traveller_${traveller.id}_locator`]:
              this.tempContract[
                `flight_${flightSection.id}_traveller_${traveller.id}_locator`
              ],
            [`flight_${flightSection.id}_traveller_${traveller.id}_value`]:
              this.tempContract[
                `flight_${flightSection.id}_traveller_${traveller.id}_value`
              ],
            [`flight_${flightSection.id}_traveller_${traveller.id}_taxes_currency`]:
              this.tempContract[
                `flight_${flightSection.id}_traveller_${traveller.id}_taxes_currency`
              ],
            [`flight_${flightSection.id}_traveller_${traveller.id}_taxes`]:
              this.tempContract[
                `flight_${flightSection.id}_traveller_${traveller.id}_taxes`
              ],
          });
        });
        flightSection.airlines.forEach((airline) => {
          this.form.setFieldsValue({
            [`flight_${flightSection.id}_airline_${airline.id}`]:
              this.tempContract[
                `flight_${flightSection.id}_airline_${airline.id}`
              ],
            [`flight_${flightSection.id}_airline_company_name_${airline.id}`]:
              this.tempContract[
                `flight_${flightSection.id}_airline_company_name_${airline.id}`
              ],
            [`flight_${flightSection.id}_airline_trading_name_${airline.id}`]:
              this.tempContract[
                `flight_${flightSection.id}_airline_trading_name_${airline.id}`
              ],
            [`flight_${flightSection.id}_airline_CNPJ_${airline.id}`]:
              this.tempContract[
                `flight_${flightSection.id}_airline_CNPJ_${airline.id}`
              ],
          });

          if (
            this.tempContract[`flight_${flightSection.id}_type`] == "Milhas"
          ) {
            this.form.setFieldsValue({
              [`flight_${flightSection.id}_airline_${airline.id}_travellers`]:
                JSON.parse(
                  this.tempContract[
                    `flight_${flightSection.id}_airline_${airline.id}_travellers`
                  ]
                ),
              [`flight_${flightSection.id}_airline_${airline.id}_miles_program`]:
                this.tempContract[
                  `flight_${flightSection.id}_airline_${airline.id}_miles_program`
                ],
            });
          }
        });

        flightSection.sections.forEach((section) => {
          this.form.setFieldsValue({
            [`flight_${flightSection.id}_section_${section.id}_type`]:
              this.tempContract[
                `flight_${flightSection.id}_section_${section.id}_type`
              ],
            [`flight_${flightSection.id}_section_${section.id}_origin`]:
              this.tempContract[
                `flight_${flightSection.id}_section_${section.id}_origin`
              ],
            [`flight_${flightSection.id}_section_${section.id}_destination`]:
              this.tempContract[
                `flight_${flightSection.id}_section_${section.id}_destination`
              ],
            [`flight_${flightSection.id}_section_${section.id}_departure_date`]:
              this.tempContract[
                `flight_${flightSection.id}_section_${section.id}_departure_date`
              ],
            [`flight_${flightSection.id}_section_${section.id}_departure_time`]:
              this.tempContract[
                `flight_${flightSection.id}_section_${section.id}_departure_time`
              ],
            [`flight_${flightSection.id}_section_${section.id}_duration`]:
              this.tempContract[
                `flight_${flightSection.id}_section_${section.id}_duration`
              ],
            [`flight_${flightSection.id}_section_${section.id}_arrival_date`]:
              this.tempContract[
                `flight_${flightSection.id}_section_${section.id}_arrival_date`
              ],
            [`flight_${flightSection.id}_section_${section.id}_class`]:
              this.tempContract[
                `flight_${flightSection.id}_section_${section.id}_class`
              ],
            [`flight_${flightSection.id}_section_${section.id}_airline`]:
              this.tempContract[
                `flight_${flightSection.id}_section_${section.id}_airline`
              ],
            [`flight_${flightSection.id}_section_${section.id}_arrival_time`]:
              this.tempContract[
                `flight_${flightSection.id}_section_${section.id}_arrival_time`
              ],
            [`flight_${flightSection.id}_section_${section.id}_number`]:
              this.tempContract[
                `flight_${flightSection.id}_section_${section.id}_number`
              ],
            [`flight_${flightSection.id}_section_${section.id}_stops`]:
              this.tempContract[
                `flight_${flightSection.id}_section_${section.id}_stops`
              ],
          });
        });

        this.form.setFieldsValue({
          [`flight_${flightSection.id}_type`]:
            this.tempContract[`flight_${flightSection.id}_type`],
          [`flight_${flightSection.id}_total_price`]:
            this.tempContract[`flight_${flightSection.id}_total_price`],
          [`flight_${flightSection.id}_total_price_written`]:
            this.tempContract[`flight_${flightSection.id}_total_price_written`],
        });
      }, 400);

      this.calcFlightTotalValue(flightSection.id);
    },
    calcFlightTotalValue(id) {
      let totalValue = 0;

      let flights =
        this.tempContract["flight_sections_rows"] != undefined
          ? JSON.parse(this.tempContract.flight_sections_rows)
          : [];

      if (flights.length > 0) {
        flights[id - 1].travellers.forEach((traveller) => {
          let currency =
            this.tempContract[
              `flight_${id}_traveller_${traveller.id}_taxes_currency`
            ] != undefined
              ? this.tempContract[
                  `flight_${id}_traveller_${traveller.id}_taxes_currency`
                ]
              : "BRL";

          if (
            this.tempContract[`flight_${id}_traveller_${traveller.id}_value`]
          ) {
            totalValue += parse(
              this.tempContract[`flight_${id}_traveller_${traveller.id}_value`]
            );
          } else {
            totalValue += 0;
          }

          if (currency == "BRL") {
            if (
              this.tempContract[`flight_${id}_traveller_${traveller.id}_taxes`]
            ) {
              totalValue += parse(
                this.tempContract[
                  `flight_${id}_traveller_${traveller.id}_taxes`
                ]
              );
            } else {
              totalValue += 0;
            }
          } else {
            if (
              this.tempContract[`flight_${id}_traveller_${traveller.id}_taxes`]
            ) {
              let exchange = this.tempContract[`voucher_payments_exchange`];

              if (exchange != undefined) {
                totalValue +=
                  exchange.replace(",", ".") *
                  parse(
                    this.tempContract[
                      `flight_${id}_traveller_${traveller.id}_taxes`
                    ]
                  );
              } else {
                totalValue += 0;
              }
            } else {
              totalValue += 0;
            }
          }
        });
      }

      this.form.setFieldsValue({
        [`flight_${id}_total_price`]: `${totalValue
          .toFixed(2)
          .replace(".", ",")}`,
      });
    },
    clearMilesProgram(flight, airline) {
      this.form.setFieldsValue({
        [`flight_${flight}_airline_${airline}_miles_program`]: undefined,
      });
    },
    addFlightSectionRow(id) {
      this.flightSectionRows.push({
        id: id + 1,
        travellers: [{ id: 1 }],
        airlines: [{ id: 1 }],
        sections: [{ id: 1 }],
      });

      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );

      setTimeout(() => {
        this.form.setFieldsValue({
          [`flight_${id + 1}_type`]: "Regular",
        });
      }, 100);
    },
    removeFlightSectionRow(id) {
      for (var i in this.flightSectionRows) {
        if (this.flightSectionRows[i].id == id) {
          this.flightSectionRows.splice(i, 1);
          break;
        }
      }

      this.tempContract[`flight_${id}_type`] = "";

      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
    updateFlightTraveller(id, flightSectionID) {
      this.flightSectionRows[flightSectionID - 1].travellers.push({
        id: id + 1,
      });

      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );

      setTimeout(() => {
        this.form.setFieldsValue({
          [`flight_${flightSectionID}_traveller_${id + 1}`]:
            this.tempContract[
              `flight_${flightSectionID}_traveller_${id + 1}`
            ] != undefined
              ? parseInt(
                  this.tempContract[
                    `flight_${flightSectionID}_traveller_${id + 1}`
                  ]
                )
              : undefined,
          [`flight_${flightSectionID}_traveller_${id + 1}_value`]:
            this.tempContract[
              `flight_${flightSectionID}_traveller_${id + 1}_value`
            ],
          [`flight_${flightSectionID}_traveller_${id + 1}_miles`]:
            this.tempContract[
              `flight_${flightSectionID}_traveller_${id + 1}_miles`
            ],
          [`flight_${flightSectionID}_traveller_${id + 1}_taxes`]:
            this.tempContract[
              `flight_${flightSectionID}_traveller_${id + 1}_taxes`
            ],
          [`flight_person_first_name_${id + 1}`]:
            this.tempContract[`flight_person_first_name_${id + 1}`],
          [`flight_person_last_name_${id + 1}`]:
            this.tempContract[`flight_person_last_name_${id + 1}`],
          [`flight_person_birthday_${id + 1}`]:
            this.tempContract[`flight_person_birthday_${id + 1}`],
          [`flight_person_CPF_${id + 1}`]:
            this.tempContract[`flight_person_CPF_${id + 1}`],
          [`flight_person_price_${id + 1}`]:
            this.tempContract[`flight_person_price_${id + 1}`],
        });
      }, 200);
    },
    removeFlightTraveller(id, flightSectionID) {
      for (var i in this.flightSectionRows[flightSectionID - 1].travellers) {
        if (
          this.flightSectionRows[flightSectionID - 1].travellers[i].id == id
        ) {
          this.flightSectionRows[flightSectionID - 1].travellers.splice(i, 1);
          break;
        }
      }
      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
    updateFlightAirline(id, flightSectionID) {
      this.flightSectionRows[flightSectionID - 1].airlines.push({
        id: id + 1,
      });
      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
      setTimeout(() => {
        this.form.setFieldsValue({
          [`flight_${flightSectionID}_person_first_name_${id + 1}`]:
            this.contract.meta[
              `flight_${flightSectionID}_person_first_name_${id + 1}`
            ],
          [`flight_${flightSectionID}_person_last_name_${id + 1}`]:
            this.contract.meta[
              `flight_${flightSectionID}_person_last_name_${id + 1}`
            ],
          [`flight_${flightSectionID}_person_birthday_${id + 1}`]:
            this.contract.meta[
              `flight_${flightSectionID}_person_birthday_${id + 1}`
            ],
        });
      }, 200);
    },
    removeFlightAirline(id, flightSectionID) {
      for (var i in this.flightSectionRows[flightSectionID - 1].airlines) {
        if (this.flightSectionRows[flightSectionID - 1].airlines[i].id == id) {
          this.flightSectionRows[flightSectionID - 1].airlines.splice(i, 1);
          break;
        }
      }
      this.tempContract["flight_sections_rows"] = JSON.stringify(
        this.flightSectionRows
      );
    },
    addAllTravellers(id, type) {
      let travellers = [];

      this.travellers.forEach((traveller) => {
        if (traveller.name && traveller.lastName) {
          travellers.push({ id: traveller.id });
        }
      });

      if (travellers.length > 0) {
        if (type == "flight") {
          for (let i in this.flightSectionRows) {
            if (this.flightSectionRows[i].id == id) {
              this.flightSectionRows[i].travellers = travellers;
              break;
            }
          }

          setTimeout(() => {
            travellers.forEach((traveller) => {
              this.form.setFieldsValue({
                [`flight_${id}_traveller_${traveller.id}`]: traveller.id,
              });

              setTimeout(() => {
                this.tempContract[
                  `flight_${id}_traveller_${traveller.id}_age`
                ] = this.tempContract[`traveller_${traveller.id}_age`];
              }, 20);
            });
          }, 10);

          this.tempContract.flight_sections_rows = JSON.stringify(
            this.flightSectionRows
          );
        }
      } else {
        this.$message.warning("Preencha os viajantes.");
      }
    },
    checkIfMilesOrderExists(flightId, airlineId) {
      let flag = false;

      if (
        this.tempContract[
          `flight_${flightId}_airline_${airlineId}_miles_order_id`
        ] != undefined &&
        this.tempContract[
          `flight_${flightId}_airline_${airlineId}_miles_order_id`
        ] != ""
      )
        flag = true;

      return flag;
    },
    milesOrderExists(flightId) {
      let flag = false;

      if (!this.$root.isAdmin()) {
        if (this.tempContract.flight_sections_rows != undefined) {
          JSON.parse(this.tempContract.flight_sections_rows).forEach(
            (flight) => {
              if (flight.id == flightId) {
                flight.airlines.forEach((airline) => {
                  if (
                    this.tempContract[
                      `flight_${flight.id}_airline_${airline.id}_miles_order_id`
                    ] != undefined
                  )
                    flag = true;
                });
              }
            }
          );
        }
      }
      return flag;
    },
    flightTravellerAge(flightId, travellerId) {
      setTimeout(() => {
        let id =
          this.tempContract[`flight_${flightId}_traveller_${travellerId}`];

        this.tempContract[`flight_${flightId}_traveller_${travellerId}_age`] =
          this.tempContract[`traveller_${id}_age`];
      }, 10);
    },
  },
};
</script>

<style lang="sass" scoped>
.btn-miles
  font-weight: 600
  font-size: 14px
  border-radius: 10px
  margin-bottom: 14px
  position: relative
  border-color: #ddd !important
  .ant-checkbox
    top: 0.1px !important
  label
    overflow: visible !important
    font-weight: 500 !important
    color: #666 !important
    transition: .3s !important
    opacity: 1 !important
    top: 1px !important

.flights-collapse
  > .ant-collapse-item
    border-top: 0 !important
    border-bottom: 1px solid #eee !important
  .flight-type
    border-bottom: 0 !important
.flights-collapse:last-child
   .ant-collapse-item
      border-bottom: 0 !important
</style>
